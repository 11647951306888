'use client';

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';
import { AddressType, AddressFormType } from '@akinon/next/types';
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Radio,
  Select
} from '@theme/components';
import {
  useGetCountriesQuery,
  useGetCitiesQuery,
  useRemoveAddressMutation,
  useGetTownshipsQuery
} from '@akinon/next/data/client/address';
import { useLocalization } from '@akinon/next/hooks';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';
import { getCookie, removeCookie } from '@akinon/next/utils';
import useUserLocation from '@theme/hooks/use-user-location';

import {
  handleChangePlaceholder,
  phoneValidate
} from '@theme/utils/phone-validate';
import { getCountryCode } from '../utils';
import { twMerge } from 'tailwind-merge';

type SelectOptionType = {
  label: string;
  value: string | number;
};

interface Props {
  data?: any;
  onSubmit: (data: any) => void;
  setIsEditAddressModalOpen?: (value: boolean) => void;
}

const makeAddressFormSchema = (t, localeCountry) =>
  yup.object().shape({
    title: yup.string().required(t('account.address_book.form.error.required')),
    first_name: yup
      .string()
      .required(t('account.address_book.form.error.required'))
      .max(255, t('account.address_book.form.error.line_max')),
    last_name: yup
      .string()
      .required(t('account.address_book.form.error.required'))
      .max(255, t('account.address_book.form.error.line_max')),
    phone_number: yup
      .string()
      .transform((value: string) => value.replace(/_/g, '').replace(/ /g, ''))
      .when('country_code', (countryCode: string, schema) => {
        const message = {
          error_with: t('account.address_book.form.error.phone_with'),
          error_without: t('account.address_book.form.error.phone_without')
        };
        return phoneValidate(t, message, countryCode, schema, localeCountry);
      })
      .required(t('account.address_book.form.error.required')),
    country: yup
      .string()
      .required(t('account.address_book.form.error.required')),
    city: yup.string().required(t('account.address_book.form.error.required')),
    flat: yup
      .string()
      .required(t('account.address_book.form.error.required'))
      .max(255, t('account.address_book.form.error.line_max')),
    street: yup
      .string()
      .required(t('account.address_book.form.error.required'))
      .max(255, t('account.address_book.form.error.line_max')),
    area: yup
      .string()
      .required(t('account.address_book.form.error.required'))
      .max(255, t('account.address_book.form.error.line_max')),
    line: yup.string(),
    primary: yup.boolean(),
    zone: yup.string().when([], {
      is: () => localeCountry == 'qa',
      then: yup
        .string()
        .required(t('account.address_book.form.error.required'))
        .max(255, t('account.address_book.form.error.line_max'))
    }),
    building_block: yup.string().when([], {
      is: () => localeCountry == 'qa',
      then: yup
        .string()
        .required(t('account.address_book.form.error.required'))
        .max(255, t('account.address_book.form.error.line_max'))
    })
  });

type LocationDataType = {
  address?: string;
  country?: string;
  city?: string;
  street?: string;
  township?: string;
};

interface CustomAddressFormType extends AddressFormType {
  flat: string;
  street: string;
  area: string;
  primary: boolean;
  zone?: string;
  building_block?: string;
}

export const AddressForm = (props: Props) => {
  const { t, locale, currency } = useLocalization();
  const [step, setStep] = useState<number>(1);
  const [phonePlaceholderFormat, setPhonePlaceholderFormat] = useState({
    placeholder: '56XXXXXXX',
    format: '999999999'
  });
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const localeCountry = locale.split('-')[1];

  const { data, onSubmit, setIsEditAddressModalOpen } = props;
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    reset,
    formState: { errors },
    setValue,
    setError
  } = useForm<CustomAddressFormType>({
    resolver: yupResolver(makeAddressFormSchema(t, localeCountry)),
    defaultValues: {
      is_corporate: AddressType.individual,
      type: 'customer',
      country: '',
      title: 'Home',
      flat: '',
      street: '',
      area: ''
    }
  });
  const selectedCountry = watch('country');
  const selectedCity = watch('city');
  const selectedTownship = watch('area');
  const titleValue = watch('title');

  const [removeAddress] = useRemoveAddressMutation();
  const { data: country } = useGetCountriesQuery();
  const { data: city } = useGetCitiesQuery(selectedCountry, {
    skip: !selectedCountry
  });
  const { data: township } = useGetTownshipsQuery(selectedCity, {
    skip: !selectedCity
  });

  const [openPopover, setOpenPopover] = useState(false);
  const [selectedLocationData, setSelectedLocationData] =
    useState<LocationDataType>({});
  const [userLocation, setUserLocation] = useState({
    lat: 24.4704056,
    lng: 54.3704445,
    zoom: 14
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isCityEditable, setIsCityEditable] = useState(false);
  const [isAreaEditable, setIsAreaEditable] = useState(false);
  const cookieLocation = JSON.parse(getCookie('userLocation') || '{}');
  const [selectedLocation, setSelectedLocation] = useState({
    lat: Number(cookieLocation.latitude),
    lng: Number(cookieLocation.longitude)
  });
  const { location, setLocation } = useUserLocation();
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  const staticCountryCodes = [
    { label: '+971', value: '971' },
    { label: '+966', value: '966' },
    { label: '+968', value: '968' },
    { label: '+974', value: '974' },
    { label: '+973', value: '973' },
    { label: '+965', value: '965' }
  ];

  const dataCountryCode = data?.phone_number?.substring(1, 4);
  const [countryCode, setCountryCode] = useState(
    dataCountryCode || getCountryCode(locale)
  );
  const [mapCenter, setMapCenter] = useState(
    selectedLocation || {
      lat: Number(cookieLocation.latitude),
      lng: Number(cookieLocation.longitude)
    }
  );
  const mapRef = useRef(null);
  const previousCenter = useRef(mapCenter);
  const erp_code = getCookie('erp_code');

  useEffect(() => {
    setValue('country_code', countryCode);
    handleChangePlaceholder(countryCode, setPhonePlaceholderFormat);
  }, [countryCode, setValue]);

  const handleChangeCountryCode = (code: string) => {
    setValue('country_code', code);
    handleChangePlaceholder(code, setPhonePlaceholderFormat);
    setValue('phone_number', '');
  };

  useEffect(() => {
    if (data && country) {
      const { line, ...restData } = data;
      let flat = '';
      let street = '';
      let zone = '';
      let building_block = '';

      if (line) {
        const parts = line.split('|').map((part) => part.trim());
        flat =
          parts
            .find((part) => part.startsWith('Flat & Building:'))
            ?.replace('Flat & Building:', '')
            .trim() || '';
        street =
          parts
            .find((part) => part.startsWith('Street:'))
            ?.replace('Street:', '')
            .trim() || '';
        zone =
          parts
            .find((part) => part.startsWith('Zone:'))
            ?.replace('Zone:', '')
            .trim() || '';
        building_block =
          parts
            .find((part) => part.startsWith('Building Block:'))
            ?.replace('Building Block:', '')
            .trim() || '';
      }

      reset({
        ...restData,
        area: data.township?.pk.toString(),
        phone_number: data.phone_number?.replace(`+${countryCode}`, ''),
        country_code: countryCode,
        flat,
        street,
        zone,
        building_block,
        is_corporate:
          String(data.is_corporate) === AddressType.company ? 'true' : 'false'
      });
    }
  }, [data, country, reset]);

  useEffect(() => {
    const lat = Number(cookieLocation?.latitude);
    const lng = Number(cookieLocation?.longitude);

    if (lat && lng) {
      setUserLocation({ lat, lng, zoom: 14 });
    }
  }, [location]);

  useEffect(() => {
    if (errors.phone_number && phoneNumberRef.current) {
      phoneNumberRef.current.focus();
      phoneNumberRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [errors.phone_number]);

  useEffect(() => {
    const cityNumber = parseInt(selectedCity);

    if (isNaN(cityNumber)) {
      setIsCityEditable(true);
    } else {
      setIsCityEditable(false);
      if (selectedTownship) {
        setIsAreaEditable(false);
      } else {
        setIsAreaEditable(true);
      }
    }
  }, [selectedLocationData]);

  useEffect(() => {
    if (selectedCity === undefined) {
      reset({
        ...getValues(),
        city: ''
      });
    } else {
      const cityNumber = parseInt(selectedCity);

      if (isNaN(cityNumber)) {
        setIsAreaEditable(true);
      }
    }
  }, [selectedCity]);

  const countryOptions = useMemo(() => {
    if (country) {
      const options: SelectOptionType[] = [{ label: 'Country', value: '' }];
      options.push(
        ...country.results.map((item) => ({ label: item.name, value: item.pk }))
      );
      return options;
    }
    return [];
  }, [country]); // eslint-disable-line react-hooks/exhaustive-deps

  const cityOptions = useMemo(() => {
    const options: SelectOptionType[] = [
      {
        label: 'Select City',
        value: ''
      }
    ];

    if (city) {
      options.push(
        ...city.results.map((item) => ({ label: item.name, value: item.pk }))
      );
    }

    return options;
  }, [city]); // eslint-disable-line react-hooks/exhaustive-deps

  const townshipOptions = useMemo(() => {
    const options: SelectOptionType[] = [
      {
        label: 'Select Area',
        value: ''
      }
    ];

    if (township) {
      options.push(
        ...township.results.map((item) => ({
          label: item.name,
          value: item.pk
        }))
      );
    }

    return options;
  }, [township]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLoad = (autocompleteInstance) => {
    autocompleteInstance.setComponentRestrictions({
      country: locale.split('-')[1]
    });

    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    handlePlaceSelect();
  };

  const fetchAddress = async (location) => {
    let data;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?loading=async&latlng=${location.lat},${location.lng}&key=${process.env.NEXT_PUBLIC_MAP_API_KEY || 'AIzaSyARU3JY2ZLLxrjKd5sa9FK0Gk368lvxI9g'}`
      );
      const mapsData = await response.json();
      data = mapsData;

      if (mapsData.results && mapsData.results.length > 0) {
        const addressComponents = mapsData.results.find(
          (result) =>
            result.address_components && result.address_components.length > 0
        )?.address_components;

        if (addressComponents) {
          const country = addressComponents.find((component) =>
            component.types.includes('administrative_area_level_1')
          );

          const city = addressComponents.find((component) =>
            component.types.includes('locality')
          );

          const township = addressComponents.find((component) =>
            component.types.includes('sublocality_level_1')
          );

          const street = addressComponents.find((component) =>
            component.types.includes('route')
          );

          const formattedAddress = mapsData.results[0].formatted_address;
          let addressResult;

          if (formattedAddress.includes(' - ')) {
            addressResult = formattedAddress.split(' - ').slice(1);
          } else if (formattedAddress.includes(',')) {
            addressResult = formattedAddress.split(',').slice(1);
          } else {
            addressResult = [formattedAddress];
          }

          const finalAddress = addressResult.join(' - ');

          const selectedLocationData = {
            address: finalAddress,
            country: country?.long_name,
            city: city?.long_name,
            street: street?.long_name,
            township: township?.long_name
          };

          setSelectedLocationData(selectedLocationData);

          if (city) {
            const cityOption = cityOptions.find(
              (option) => option.label === city.long_name
            );
            if (cityOption) {
              reset({
                ...getValues(),
                city: cityOption.value.toString()
              });
            }

            if (street) {
              reset({
                ...getValues(),
                street: street.long_name
              });
            }

            if (township) {
              const townshipOption = townshipOptions.find(
                (option) => option.label === township?.long_name
              );

              if (townshipOption) {
                reset({
                  ...getValues(),
                  area: townshipOption.value.toString()
                });
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }

    return data;
  };

  const onSubmitHandler = async (formData) => {
    const userLocation = JSON.parse(getCookie('userLocation') || '{}');
    formData.phone_number = `+${countryCode}${formData.phone_number}`;

    const wasPrimary = data
      ? (data?.extra_field as { address_primary: string })?.address_primary ===
        'true'
      : false;

    const ffcInfo = {
      latitude: selectedLocation?.lat || userLocation?.latitude,
      longitude: selectedLocation?.lng || userLocation?.longitude,
      address_primary: wasPrimary ? 'true' : String(formData.primary),
      currency: currency,
      ffc_last_updated_datetime: new Date().toISOString()
    };

    const { ffc } = await setLocation(ffcInfo);

    if (!erp_code) {
      setShowError(true);
    } else {
      setShowError(false);
    }

    formData.line = `Flat & Building: ${formData.flat} | Street: ${formData.street} ${formData.zone ? `| Zone: ${formData.zone}` : ''} ${formData.building_block ? `| Building Block: ${formData.building_block}` : ''} | Area: ${townshipOptions.find((option) => option.value === Number(formData.area))?.label}`;

    if (formData.line.length > 255) {
      setError('flat', {
        type: 'manual',
        message: t('account.address_book.form.error.line_max')
      });
      return;
    }

    formData.latitude = ffcInfo.latitude;
    formData.longitude = ffcInfo.longitude;
    formData.address_primary = wasPrimary ? 'true' : String(formData.primary);
    formData.ffc = ffc;
    formData.township = selectedTownship;

    delete formData.flat;
    delete formData.street;
    delete formData.area;
    delete formData?.zone;
    delete formData?.building_block;

    onSubmit(formData);
  };

  const handlePlaceSelect = async () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const location = place.geometry?.location;
      const ffcInfo = {
        latitude: location.lat(),
        longitude: location.lng(),
        currency: currency
      };

      if ((await checkCountry(location.lat(), location.lng())) == true) {
        const { ffc } = await setLocation(ffcInfo);

        if (!ffc) {
          setShowError(true);
        } else {
          setShowError(false);
          setIsLocationSelected(true);
        }
        const newLocation = {
          lat: location.lat(),
          lng: location.lng()
        };
        setSelectedLocation(newLocation);
        fetchAddress(newLocation);
      } else {
        setSelectedLocation(null);
        setShowError(true);
      }
    }
  };

  const checkCountry = async (lat, lng) => {
    const countryInfo = {
      lat: lat,
      lng: lng
    };

    const response = await fetchAddress(countryInfo);

    const addressComponents = response.results.find(
      (result) =>
        result.address_components && result.address_components.length > 0
    )?.address_components;
    const country = addressComponents.find((component) =>
      component.types.includes('country')
    );

    if (!country || country.short_name.toLowerCase() !== locale.split('-')[1]) {
      return false;
    } else {
      return true;
    }
  };

  const handleMapClick = async (event) => {
    const ffcInfo = {
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
      currency: currency
    };

    if ((await checkCountry(event.latLng.lat(), event.latLng.lng())) == true) {
      const { ffc } = await setLocation(ffcInfo);

      if (!ffc) {
        setShowError(true);
      } else {
        setShowError(false);
        setIsLocationSelected(true);
      }
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      setSelectedLocation(newLocation);
      fetchAddress(newLocation);
    } else {
      setSelectedLocation(null);
      setShowError(true);
    }
  };

  const clearSearch = () => {
    setSelectedLocationData({});
    setSelectedLocation(null);
    setSelectedLocationData({ ...selectedLocationData, address: '' });
    reset({
      title: 'Home',
      first_name: '',
      last_name: '',
      phone_number: '',
      country: '',
      city: '',
      area: '',
      flat: '',
      street: '',
      primary: false,
      zone: '',
      building_block: ''
    });
    setStep(1);

    fillCountryField();
  };

  const handleRemoveAddress = async (addressPk: number) => {
    await removeAddress(addressPk);

    removeCookie('selectedAddress');

    if (setIsEditAddressModalOpen) {
      setTimeout(() => {
        setIsEditAddressModalOpen(false);
      }, 1000);
    }
  };

  const fillCountryField = () => {
    if (country && localeCountry) {
      const matchedCountry = country.results.find(
        (item) => item.code.toLowerCase() === localeCountry
      );

      if (matchedCountry) {
        setValue('country', matchedCountry.pk.toString());
      }
    }
  };

  useEffect(() => {
    fillCountryField();
  }, [country, localeCountry, setValue]);

  const onMapLoad = (map) => {
    mapRef.current = map;
  };

  const onDragEnd = async () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      const ffcInfo = {
        latitude: newCenter.lat(),
        longitude: newCenter.lng(),
        currency: currency
      };

      if ((await checkCountry(newCenter.lat(), newCenter.lng())) == true) {
        const { ffc } = await setLocation(ffcInfo);

        if (!ffc) {
          setShowError(true);
        } else {
          setShowError(false);
        }
        const newLocation = {
          lat: newCenter.lat(),
          lng: newCenter.lng()
        };
        setSelectedLocation(newLocation);
        fetchAddress(newLocation);
        setIsLocationSelected(true);
      } else {
        setSelectedLocation(null);
        setShowError(true);
      }
    }
  };

  const onCenterChanged = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      const newLat = newCenter.lat();
      const newLng = newCenter.lng();

      if (
        previousCenter.current.lat !== newLat ||
        previousCenter.current.lng !== newLng
      ) {
        setMapCenter({ lat: newLat, lng: newLng });
        previousCenter.current = { lat: newLat, lng: newLng };
      }
    }
  };

  return (
    <div className="flex h-full w-full flex-col">
      <div
        className={clsx(
          'absolute left-1.5 top-[21px] hidden cursor-pointer md:flex',
          step == 1 && 'lg:hidden'
        )}
        onClick={() => setStep((prevStep) => prevStep - 1)}
      >
        <Icon name="chevron-left" size={20}></Icon>
      </div>
      <div
        className={clsx(
          'relative mb-[22px] pl-[34px] pr-[30px]',
          data && 'hidden'
        )}
      >
        <label className="absolute -top-2 left-[50px] z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
          {t('account.address_book.form.location.title')}
        </label>
        <Autocomplete
          restrictions={{ country: locale.split('-')[1] }}
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
        >
          <Input
            value={selectedLocationData?.address}
            onChange={(e: any) =>
              setSelectedLocationData({
                ...selectedLocationData,
                address: e.target.value
              })
            }
            placeholder={t('account.address_book.form.location.placeholder')}
            className="h-[54px] w-full rounded-lg border-[#C1C2C2] pe-[70px] text-base placeholder:text-sm placeholder:text-[#C1C2C2]"
            data-testid="basket-voucher-input"
          />
        </Autocomplete>
        <Button
          onClick={clearSearch}
          className="absolute bottom-0 end-8 top-0 h-full border-none bg-transparent text-black hover:bg-transparent hover:text-black"
        >
          {t('common.location.clear')}
        </Button>
      </div>
      {!data && step === 1 ? (
        <div className="flex h-full flex-col gap-8">
          <div className="flex-grow">
            <GoogleMap
              mapContainerClassName="ml-[34px] h-[408px] mr-[30px] mb-28 lg:mb-0 rounded-xl"
              center={
                selectedLocation || {
                  lat: Number(cookieLocation.latitude),
                  lng: Number(cookieLocation.longitude)
                }
              }
              zoom={14}
              options={{
                disableDefaultUI: true,
                zoomControl: true
              }}
              onClick={handleMapClick}
              onLoad={onMapLoad}
              onCenterChanged={onCenterChanged}
              onDragEnd={onDragEnd}
            >
              <Marker
                icon={{
                  fillColor: '#4285F4',
                  fillOpacity: 1,
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 8,
                  strokeColor: 'rgb(255,255,255)',
                  strokeWeight: 2
                }}
                position={mapCenter}
              />{' '}
            </GoogleMap>
          </div>
          <div className="flex w-full items-center justify-between border-t bg-gray-25 py-3 pl-[34px] pr-[26px]">
            <div className="flex items-center gap-1">
              {(openPopover && !selectedLocation) ||
                (!isLocationSelected && (
                  <>
                    <Icon name="info" className="text-primary" size={14}></Icon>
                    <p className="text-xs text-primary">
                      {t('account.address_book.modal.map_info')}
                    </p>
                  </>
                ))}
            </div>

            {showError && (
              <div className="flex items-center justify-center">
                <Icon
                  name="info"
                  className="mr-1 text-error-650"
                  size={14}
                ></Icon>
                <p className="text-xs text-error-650">
                  {t('account.address_book.modal.invalid_address')}
                </p>
              </div>
            )}

            <div
              onMouseEnter={() => !selectedLocation && setOpenPopover(true)}
              onMouseLeave={() => !selectedLocation && setOpenPopover(false)}
            >
              <Button
                disabled={!selectedLocation || showError || !isLocationSelected}
                onClick={() => setStep((step) => step + 1)}
                className={twMerge(
                  clsx(
                    'h-[49px] w-[98px] rounded-[136px] bg-primary text-base font-normal',
                    (!selectedLocation || showError || !isLocationSelected) &&
                      'opacity-40 hover:bg-primary hover:text-white'
                  )
                )}
              >
                {t('account.address_book.modal.next')}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          className={clsx('flex h-full flex-col gap-5', !data && 'mt-10')}
        >
          <div className="flex h-[calc(100vh-300px)] flex-grow flex-col gap-[22px] overflow-y-auto px-[34px]">
            <div className="no-scrollbar mb-[18px] flex min-h-[34px] gap-2.5 overflow-auto">
              <Radio
                {...register('title')}
                value="Home"
                data-testid="address-type-home"
                required
                className={clsx(
                  'flex items-center gap-2 rounded-md border p-1.5 pe-3',
                  titleValue === 'Home' &&
                    'border-primary bg-primary bg-opacity-20'
                )}
                labelClassName={clsx(
                  'ms-0 flex items-center gap-2 text-sm font-semibold text-black',
                  titleValue === 'Home' && 'text-primary'
                )}
                inputClassName="hidden"
              >
                <Icon className="text-primary" size={14} name="home"></Icon>
                {t('account.address_book.form.address_title.home')}
              </Radio>
              <Radio
                {...register('title')}
                value="Office"
                data-testid="address-type-office"
                required
                className={clsx(
                  'flex items-center gap-2 rounded-md border p-1.5 pe-3',
                  titleValue === 'Office' &&
                    'border-primary bg-primary bg-opacity-20'
                )}
                labelClassName={clsx(
                  'ms-0 flex items-center gap-2 text-sm font-semibold text-black',
                  titleValue === 'Office' && 'text-primary'
                )}
                inputClassName="hidden"
              >
                <Icon className="text-primary" size={14} name="building"></Icon>
                {t('account.address_book.form.address_title.office')}
              </Radio>
              <Radio
                {...register('title')}
                value="Friend House"
                data-testid="address-type-friend"
                required
                className={clsx(
                  'pe- flex items-center gap-2 rounded-md border p-1.5',
                  titleValue === 'Friend House' &&
                    'border-primary bg-primary bg-opacity-20'
                )}
                labelClassName={clsx(
                  'ms-0 flex items-center gap-2 truncate text-sm font-semibold text-black',
                  titleValue === 'Friend House' && 'text-primary'
                )}
                inputClassName="hidden"
              >
                <Icon
                  className="text-primary"
                  size={14}
                  name="profile-2user"
                ></Icon>
                {t('account.address_book.form.address_title.friend_house')}
              </Radio>
              <Radio
                {...register('title')}
                value="Other"
                data-testid="address-type-other"
                required
                className={clsx(
                  'flex items-center gap-2 rounded-md border p-1.5 pe-3',
                  titleValue === 'Other' &&
                    'border-primary bg-primary bg-opacity-20'
                )}
                labelClassName={clsx(
                  'ms-0 flex items-center gap-2 text-sm font-semibold text-black',
                  titleValue === 'Other' && 'text-primary'
                )}
                inputClassName="hidden"
              >
                <Icon
                  className="text-primary"
                  size={14}
                  name="building-2"
                ></Icon>
                {t('account.address_book.form.address_title.other')}
              </Radio>
            </div>
            <div className="flex w-full gap-3.5">
              <div className="relative w-full">
                <label className="absolute -top-2 left-4 z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
                  {t('account.address_book.form.name.placeholder')}{' '}
                  <span className="text-error">*</span>
                </label>
                <Input
                  {...register('first_name')}
                  placeholder={t('account.address_book.form.name.placeholder')}
                  error={errors.first_name}
                  data-testid="address-form-first-name"
                  className="h-[54px] w-full rounded-lg border-[#C1C2C2] text-sm placeholder:text-sm placeholder:text-[#C1C2C2]"
                  required
                />
              </div>
              <div className="relative w-full">
                <label className="absolute -top-2 left-4 z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
                  {t('account.address_book.form.surname.placeholder')}{' '}
                  <span className="text-error">*</span>
                </label>
                <Input
                  {...register('last_name')}
                  placeholder={t(
                    'account.address_book.form.surname.placeholder'
                  )}
                  error={errors.last_name}
                  data-testid="address-form-last-name"
                  className="h-[54px] w-full rounded-lg border-[#C1C2C2] text-sm placeholder:text-sm placeholder:text-[#C1C2C2]"
                  required
                />
              </div>
            </div>

            <div className="relative w-full">
              <label className="absolute -top-2 left-4 z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
                {t('account.address_book.form.flat.title')}{' '}
                <span className="text-error">*</span>
              </label>
              <Input
                {...register('flat')}
                placeholder={t('account.address_book.form.flat.placeholder')}
                error={errors.flat}
                data-testid="address-form-flat"
                className="h-[54px] w-full rounded-lg border-[#C1C2C2] text-sm placeholder:text-sm placeholder:text-[#C1C2C2]"
                required
              />
            </div>
            <div className="relative w-full">
              <label className="absolute -top-2 left-4 z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
                {t('account.address_book.form.street.title')}{' '}
                <span className="text-error">*</span>
              </label>
              <Input
                {...register('street')}
                placeholder={t('account.address_book.form.street.placeholder')}
                error={errors.street}
                data-testid="address-form-street"
                className="h-[54px] w-full rounded-lg border-[#C1C2C2] text-sm placeholder:text-sm placeholder:text-[#C1C2C2]"
                required
              />
            </div>

            {!getValues('city') && (
              <div className="-mb-2 text-sm">
                {t('account.address_book.form.province.empty_city')}
              </div>
            )}

            <div className="flex w-full justify-between gap-3.5">
              <div className="relative w-full">
                <label className="absolute -top-2 left-4 z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
                  {t('account.address_book.form.area.title')}{' '}
                  <span className="text-error">*</span>
                </label>

                <Select
                  disabled={
                    !getValues('city') || !isAreaEditable ? true : false
                  }
                  className="h-[54px] w-full cursor-default appearance-none border-[#C1C2C2] bg-white pe-5 text-sm !text-black hover:border-[#C1C2C2]"
                  options={townshipOptions}
                  {...register('area')}
                  error={errors.area}
                  iconClassName={clsx(
                    '!text-black',
                    data || (getValues('area') && !isAreaEditable && 'hidden')
                  )}
                  data-testid="address-form-city"
                  labelClassName="w-full"
                  required
                />
              </div>
              {cityOptions && (
                <div className="relative w-full">
                  <label className="absolute -top-2 left-4 z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
                    {t('account.address_book.form.province.title')}{' '}
                    <span className="text-error">*</span>
                  </label>
                  <Select
                    disabled={
                      getValues('city') && !isCityEditable ? true : false
                    }
                    className="h-[54px] w-full cursor-default appearance-none border-[#C1C2C2] bg-white pe-5 text-sm !text-black hover:border-[#C1C2C2]"
                    options={cityOptions}
                    {...register('city')}
                    error={errors.city}
                    iconClassName={clsx(
                      '!text-black',
                      data || (getValues('city') && !isCityEditable && 'hidden')
                    )}
                    data-testid="address-form-city"
                    labelClassName="w-full"
                    required
                  />
                </div>
              )}
            </div>
            {localeCountry === 'qa' && (
              <div className="flex w-full gap-3.5">
                <div className="relative w-full">
                  <label className="absolute -top-2 left-4 z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
                    {t('account.address_book.form.zone.title')}
                  </label>
                  <Input
                    placeholder={t(
                      'account.address_book.form.zone.placeholder'
                    )}
                    {...register('zone')}
                    error={errors.zone}
                    data-testid="address-form-first-name"
                    className="h-[54px] w-full rounded-lg border-[#C1C2C2] text-sm placeholder:text-sm placeholder:text-[#C1C2C2]"
                    required
                  />
                </div>
                <div className="relative w-full">
                  <label className="absolute -top-2 left-4 z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
                    {t('account.address_book.form.building_block.title')}
                  </label>
                  <Input
                    placeholder={t(
                      'account.address_book.form.building_block.placeholder'
                    )}
                    {...register('building_block')}
                    error={errors.building_block}
                    data-testid="address-form-last-name"
                    className="h-[54px] w-full rounded-lg border-[#C1C2C2] text-sm placeholder:text-sm placeholder:text-[#C1C2C2]"
                    required
                  />
                </div>
              </div>
            )}
            <input type="hidden" {...register('line')} />
            <p className="mb-2.5 text-sm text-gray-620">
              {t('account.address_book.form.country.selected_country')}{' '}
              <span className="text-primary">
                {
                  countryOptions?.find(
                    (item) => item.value === Number(selectedCountry)
                  )?.label
                }
              </span>
            </p>
            <Checkbox
              {...register('primary')}
              className={clsx(data?.primary && 'hidden')}
              inputClassName="border-black border-opacity-40 rounded"
              labelClassName="text-black text-sm font-normal"
            >
              {t('account.address_book.modal.set_default')}{' '}
            </Checkbox>
            <div className="relative mt-[28px] w-full rounded-lg border border-[#C1C2C2] hover:border-black">
              <label className="absolute -top-2 left-4 z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
                {t('account.address_book.form.phone.placeholder')}{' '}
                <span className="text-error">*</span>
              </label>

              {/* Numbers should always be presented as left-to-right, even in rtl languages */}
              <div dir="ltr" className="flex items-center justify-between">
                <div className="flex w-full items-center">
                  <Select
                    options={staticCountryCodes}
                    onChange={(e) => {
                      const target = e.target as HTMLSelectElement;
                      handleChangeCountryCode(target.value);
                      setCountryCode(target.value);
                    }}
                    selectedValue={countryCode}
                    iconClassName="start-auto -end-1"
                    className="ms-4 w-[73px] border-none bg-white !pe-0 !ps-0 text-base font-normal"
                  ></Select>
                  <Input
                    placeholder={phonePlaceholderFormat.placeholder}
                    format={phonePlaceholderFormat.format.replace(/\9/g, '#')}
                    maxLength={phonePlaceholderFormat.format.length}
                    control={control}
                    {...register('phone_number')}
                    className="h-[54px] w-full rounded-lg border-none text-sm placeholder:text-sm placeholder:text-[#C1C2C2]"
                    data-testid="address-form-phone"
                    ref={phoneNumberRef}
                    required
                  />
                </div>
              </div>
            </div>
            {errors.phone_number && (
              <p className="text-sm text-error">
                {errors.phone_number.message}
              </p>
            )}
          </div>
          <div className="flex w-full justify-between gap-[22px] border-t bg-gray-25 py-3 pl-[34px] pr-[26px] md:justify-end">
            {showError && (
              <div className="flex items-center justify-center">
                <Icon
                  name="info"
                  className="mr-1 text-error-650"
                  size={14}
                ></Icon>
                <p className="text-xs text-error-650">
                  {t('account.address_book.modal.invalid_address')}
                </p>
              </div>
            )}
            <Button
              onClick={() => setStep((prevState) => prevState - 1)}
              className={clsx(
                'flex h-[49px] items-center border-gray-620 bg-gray-25 bg-opacity-20 text-black md:hidden',
                data && '!hidden',
                showError && 'w-full'
              )}
            >
              <Icon name="chevron-left" size={20}></Icon>
              Map
            </Button>
            <Button
              onClick={() => handleRemoveAddress(data?.pk)}
              className={clsx(
                'flex h-[49px] items-center gap-1 border-none bg-[#EC4149] bg-opacity-20 px-3.5 text-base text-[#EC4149] hover:bg-[#EC4149] hover:bg-opacity-40 hover:text-[#EC4149]',
                !data?.pk && '!hidden',
                showError && 'w-full'
              )}
            >
              <Icon name="trash" size={24}></Icon>
              {t('account.address_book.modal.delete_address')}
            </Button>
            <Button
              type="submit"
              className="h-[49px] w-[167px] rounded-[136px] bg-primary text-base font-normal"
              data-testid="address-form-submit"
            >
              {t('account.address_book.modal.save_address')}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
